//  TODO temporary solution until Piler will load / expose the used components
export const usedEEComponents = [
	'WRichText',
	'ResponsiveBox',
	'AppWidget',
	'VectorImage',
	'StylableButton',
	'ImageX',
	'VerticalLine',
]
